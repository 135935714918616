import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SidebarExpand, Settings, GraphUp, Flash, Mail, LightBulb, LogOut, User } from 'iconoir-react';
import { useAuth } from '../context/Auth';

interface SidebarProps {
  avatarUrl: string | null;
}

function Sidebar({ avatarUrl }: SidebarProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [showLogoutTooltip, setShowLogoutTooltip] = useState(false);
  const location = useLocation();
  const tooltipRef = useRef(null);
  const accountButtonRef = useRef(null);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleLogoutTooltip = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setShowLogoutTooltip(!showLogoutTooltip);
  };

  const handleLogout = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    logout();
    navigate('/login');
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current &&
          !(tooltipRef.current as any).contains(event.target) &&
          accountButtonRef.current &&
          !(accountButtonRef.current as any).contains(event.target)) {
        setShowLogoutTooltip(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const sidebarItems = [
    { icon: <Mail className="h-5 w-5" />, text: 'Inbox', path: '/' },
    { icon: <GraphUp className="h-5 w-5" />, text: 'Insights', path: '/insights' },
    { icon: <Flash className="h-5 w-5" />, text: 'Automations', path: '/automations' },
    { icon: <LightBulb className="h-5 w-5" />, text: 'Signals', path: '/signals' },
  ];

  return (
    <div
      className={`h-screen ${isExpanded ? 'w-64' : 'w-16'} bg-gray-100 flex flex-col py-4 transition-all duration-300 ease-in-out overflow-visible`}
    >
      {/* Maxium Button */}
      <div
        className="relative flex items-center cursor-pointer mb-6 px-3 py-2"
        onClick={toggleSidebar}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span className="text-2xl w-10 h-10 flex items-center justify-center flex-shrink-0">
          {isHovered && !isExpanded ? <SidebarExpand className="h-5 w-5" /> : (
            <img src="/maxium-icon.png" alt="Maxium Icon" className="w-8 h-8 object-contain" />
          )}
        </span>
        <span className={`ml-3 font-bold font-inter text-2xl transition-opacity duration-300 ${isExpanded ? 'opacity-100' : 'opacity-0'}`}>
          Maxium
        </span>

        {/* Hover Tooltip */}
        {!isExpanded && isHovered && (
          <div className="absolute left-full ml-2 bg-black text-white text-xs rounded py-1 px-2 whitespace-nowrap z-10">
            Expand Sidebar
          </div>
        )}
      </div>

      {/* Sidebar Icons */}
      <div className="flex-grow space-y-1">
        {sidebarItems.map((item, index) => (
          <Link
            key={index}
            to={item.path}
            className={`flex items-center px-3 py-2 relative group`}
          >
            <span
              className={`absolute inset-0 left-2 right-2 top-2 bottom-2 bg-white rounded-lg transition-opacity duration-300 z-0
              ${location.pathname === item.path ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'}`}
            ></span>

            <span className={`text-2xl w-10 h-10 flex items-center justify-center flex-shrink-0 relative z-10
                  ${location.pathname === item.path ? 'text-indigo-400' : 'text-gray-700'}`}>
              {item.icon}
            </span>
            <span className={`ml-3 text-base whitespace-nowrap transition-opacity duration-300 relative z-10
                  ${isExpanded ? 'opacity-100' : 'opacity-0'}
                  ${location.pathname === item.path ? 'text-indigo-400' : 'text-gray-700'}`}>
              {item.text}
            </span>
          </Link>
        ))}
      </div>

      {/* Settings and Account at the Bottom */}
      <div className="mt-auto space-y-1">
        <Link
          to="/settings"
          className={`flex items-center px-3 py-2 relative group`}
        >
          <span
            className={`absolute inset-0 left-2 right-2 top-2 bottom-2 bg-white rounded-lg transition-opacity duration-300 z-0
            ${location.pathname === '/settings' ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'}`}
          ></span>

          <span className={`text-2xl w-10 h-10 flex items-center justify-center flex-shrink-0 relative z-10
                ${location.pathname === '/settings' ? 'text-indigo-400' : 'text-gray-700'}`}>
            <Settings className="h-5 w-5" />
          </span>
          <span className={`ml-3 text-base whitespace-nowrap transition-opacity duration-300 relative z-10
                ${isExpanded ? 'opacity-100' : 'opacity-0'}
                ${location.pathname === '/settings' ? 'text-indigo-400' : 'text-gray-700'}`}>
            Settings
          </span>
        </Link>
        <div
          ref={accountButtonRef}
          className={`flex items-center px-3 py-2 relative group cursor-pointer`}
          onClick={toggleLogoutTooltip}
        >
          <span
            className={`absolute inset-0 left-2 right-2 top-2 bottom-2 bg-white rounded-lg transition-opacity duration-300 z-0
            ${location.pathname === '/account' ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'}`}
          ></span>
          <span className="text-2xl w-10 h-10 flex items-center justify-center flex-shrink-0 relative z-10">
            {avatarUrl ? (
              <img src={avatarUrl} alt="User Avatar" className="w-8 h-8 rounded-full" />
            ) : (
              <User className="h-5 w-5 text-gray-700" />
            )}
          </span>
          <span className={`ml-3 text-base text-gray-700 whitespace-nowrap transition-opacity duration-300 relative z-10
                ${isExpanded ? 'opacity-100' : 'opacity-0'}`}>
            Account
          </span>

          {/* Logout Tooltip */}
          {showLogoutTooltip && (
            <div
              ref={tooltipRef}
              className={`absolute bg-white border border-gray-200 rounded-lg shadow-md py-3 px-4 whitespace-nowrap z-20`}
              style={{
                width: '150px',
                left: isExpanded ? 'calc(100% + 0.5rem)' : '4.5rem',
                bottom: 'calc(100% - 20px)', // Aligns bottom of tooltip with top of account icon
              }}
            >
              <button
                onClick={handleLogout}
                className="flex items-center w-full text-red-600 hover:text-red-700 transition-colors duration-200"
              >
                <LogOut className="h-5 w-5 mr-2" />
                Log out
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
