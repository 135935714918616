import React, { useMemo } from 'react';
import { InfoCircle, ArrowUp, ArrowDown } from 'iconoir-react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

interface TimeSeriesDataPoint {
  date: string;
  value: number;
}

interface MetricCardProps {
  title: string;
  value: number | TimeSeriesDataPoint[];
  changePercentage: number;
  timePeriod: string;
  repository: string;
  isPercentage?: boolean;
  isTimeSeries?: boolean;
  negative?: 'up' | 'down';
}

const MetricCard: React.FC<MetricCardProps> = ({
  title,
  value,
  changePercentage,
  timePeriod,
  repository,
  isPercentage = false,
  isTimeSeries = false,
  negative = 'down'
}) => {
    const sortedTimeSeriesData = useMemo(() => {
      if (isTimeSeries && Array.isArray(value)) {
        return [...value].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
      }
      return [];
    }, [value, isTimeSeries]);

    const formattedValue = useMemo(() => {
      if (isTimeSeries) {
        const timeSeriesData = sortedTimeSeriesData;
        return Array.isArray(timeSeriesData) && timeSeriesData.length > 0
          ? timeSeriesData[timeSeriesData.length - 1].value
          : 0;
      }

    const numericValue = value as number;
    return isPercentage
      ? (numericValue * 100).toFixed(1)
      : numericValue.toLocaleString();
  }, [value, isPercentage, isTimeSeries]);

  const displayValue = isPercentage ? `${formattedValue}%` : formattedValue;

  // Determine if the change is "good" or "bad" based on the negative direction
  const isPositiveChange = useMemo(() => {
    const isPositiveNumber = changePercentage >= 0;
    return negative === 'up' ? !isPositiveNumber : isPositiveNumber;
  }, [changePercentage, negative]);

  const colorScheme = isPositiveChange ? {
    text: 'text-green-600',
    icon: 'text-green-600',
    bar: '#8884d8' // green-500
  } : {
    text: 'text-red-600',
    icon: 'text-red-600',
    bar: '#8884d8' // red-500
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200 h-40 flex flex-col">
      <h3 className="text-sm font-medium text-gray-500 mb-2">{title}</h3>
      <div className="flex-grow flex flex-col justify-center overflow-hidden">
        {isTimeSeries ? (
          <div className="h-full w-full">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={sortedTimeSeriesData}
                margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
              >
                <XAxis
                  dataKey="date"
                  tick={false}
                  hide
                  reversed={false}
                />
                <YAxis hide />
                <Tooltip
                  contentStyle={{ background: 'white', border: '1px solid #ccc' }}
                  labelStyle={{ fontWeight: 'bold' }}
                  formatter={(value) => [
                    isPercentage ? `${Number(value).toFixed(1)}%` : Number(value).toLocaleString(),
                    ''
                  ]}
                  labelFormatter={(label) => new Date(label).toLocaleDateString()}
                />
                <Bar dataKey="value" fill={colorScheme.bar} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <>
            <p className="text-3xl font-bold mb-1">{displayValue}</p>
            <div className="flex items-center text-xs">
              <InfoCircle className="w-3 h-3 text-gray-400 mr-1" />
              <span className={`font-medium ${colorScheme.text}`}>
                {changePercentage >= 0 ? '+' : ''}{changePercentage.toFixed(1)}%
              </span>
              <span className="text-gray-500 ml-1">from previous period</span>
              {changePercentage >= 0 ? (
                <ArrowUp className={`w-3 h-3 ${colorScheme.icon} ml-1`} />
              ) : (
                <ArrowDown className={`w-3 h-3 ${colorScheme.icon} ml-1`} />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { MetricCard };
