import React, { useState, useMemo } from 'react';
import { User, NavArrowDown, NavArrowUp } from 'iconoir-react';
import { PullRequest } from '../types/pullRequest';

interface PullRequestListProps {
  pullRequests: PullRequest[];
}

const PullRequestList: React.FC<PullRequestListProps> = ({ pullRequests }) => {
  const [expandedSections, setExpandedSections] = useState<{ [key: string]: boolean }>({});

  const sections = useMemo(() => [
    { title: 'Needs your review', status: 'needs_your_review' },
    { title: 'Returned to you', status: 'returned_to_you' },
    { title: 'Approved', status: 'approved' },
    { title: 'Waiting for reviewers', status: 'waiting_for_reviewers' },
    { title: 'Drafts', status: 'draft' },
    { title: 'Recently merged', status: 'merging_or_recently_merged' },
  ], []);

  const sectionCounts = useMemo(() =>
    sections.reduce((counts, section) => {
      counts[section.status] = pullRequests.filter(pr => pr.status === section.status).length;
      return counts;
    }, {} as { [key: string]: number }),
  [sections, pullRequests]);

  const toggleSection = (status: string) => {
    setExpandedSections(prev => ({
      ...prev,
      [status]: !prev[status]
    }));
  };

  return (
    <div className="max-w-4xl mx-auto">
      {sections.map((section) => (
        <div key={section.status} className="bg-gray-50 rounded-lg mb-2 overflow-hidden">
          <div
            className="flex items-center p-3 cursor-pointer hover:bg-gray-100"
            onClick={() => toggleSection(section.status)}
          >
            <span className="mr-2">{expandedSections[section.status] ? <NavArrowUp className="h-4 w-4"/> : <NavArrowDown className="h-4 w-4" />}</span>
            <div className="w-6 h-6 rounded-full bg-white flex items-center justify-center mr-2">
              <span className="text-xs font-medium text-gray-600">{sectionCounts[section.status]}</span>
            </div>
            <span className="text-sm font-medium">{section.title}</span>
          </div>
          {expandedSections[section.status] && sectionCounts[section.status] > 0 && (
            <div className="bg-gray-50 px-3 pb-3">
              <div className="flex items-center border-b border-gray-200 py-2 mb-2 text-xs text-gray-500">
                <div className="flex-grow flex items-center">
                  <div className="w-8 h-8 flex items-center justify-center">
                    <User className="h-4 w-4" />
                  </div>
                  <span className='ml-2'>Title</span>
                </div>
                <div className="flex items-center">
                  <span className="w-8 text-center">CI</span>
                  <span className="w-20 text-center">Changes</span>
                  <span className="w-16 text-right">Updated</span>
                </div>
              </div>

              {pullRequests.filter(pr => pr.status === section.status).map((pr, prIndex) => (
                <div key={prIndex} className="flex items-start text-sm py-2 border-b border-gray-200 last:border-b-0">
                  {pr.avatarUrl === 'unknown' ? (
                    <div className="w-8 h-8 flex items-center justify-center">
                      <span>👤</span>
                    </div>
                  ) : (
                    <img src={pr.avatarUrl} alt={`${pr.author}'s avatar`} className="w-8 h-8 rounded-full" />
                  )}
                  <div className="flex-grow ml-2">
                    <div className="font-medium">{pr.title}</div>
                    <div className="text-gray-500 text-xs">
                      {pr.author} - {pr.repo} - {pr.prNumber}
                    </div>
                  </div>
                  <div className="flex items-center text-xs">
                    {pr.ci === 'successful' ? (
                      <span className="w-8 text-center">✅</span>
                    ) : (
                      <span className="w-8 text-center">🔄</span>
                    )}
                    <div className="w-20 text-center">
                      <span className="text-green-600">+{pr.additions}</span>
                      <span className="text-gray-400">/</span>
                      <span className="text-red-600">-{pr.deletions}</span>
                    </div>
                    <span className="w-16 text-right text-gray-500">{pr.updatedAgo}</span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PullRequestList;
