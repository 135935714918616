import React, { useState, useMemo } from 'react';
import FilterPanel from '../components/FilterPanel';
import PullRequestList from '../components/PullRequestList';
import RepositorySelector from '../components/RepositorySelector';
import { useInbox } from '../context/Inbox';
import { Spinner } from '../components/Spinner';
import { ErrorAlert } from '../components/ErrorAlert';

interface FilterState {
  authors: string[];
  reviewedBy: string[];
  taskLabels: string[];
  baseBranches: string[];
  sizesOfPR: string[];
}

const HomePage: React.FC = () => {
  const { pullRequests, isLoading, error } = useInbox();
  const [selectedRepo, setSelectedRepo] = useState<string>('All repositories');
  const [filters, setFilters] = useState<FilterState>({
    authors: [],
    reviewedBy: [],
    taskLabels: [],
    baseBranches: [],
    sizesOfPR: [],
  });

  const uniqueRepos = useMemo(() => {
    const repos = pullRequests.map(pr => pr.repo);
    const uniqueRepoSet = new Set(repos);
    return ['All repositories', ...Array.from(uniqueRepoSet).sort()];
  }, [pullRequests]);

  const filteredPullRequests = useMemo(() => {
    return pullRequests.filter((pr) => {
      if (selectedRepo !== 'All repositories' && pr.repo !== selectedRepo) return false;
      if (filters.authors.length && !filters.authors.includes(pr.author)) return false;
      if (filters.reviewedBy.length && !pr.reviewedBy.some(reviewer => filters.reviewedBy.includes(reviewer))) return false;
      if (filters.taskLabels.length && !pr.taskLabels.some(label => filters.taskLabels.includes(label))) return false;
      if (filters.baseBranches.length && !filters.baseBranches.includes(pr.baseBranch)) return false;
      if (filters.sizesOfPR.length && !filters.sizesOfPR.includes(pr.size)) return false;
      return true;
    });
  }, [selectedRepo, filters, pullRequests]);

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">Inbox</h1>
        <RepositorySelector
          selectedRepo={selectedRepo}
          onSelectRepo={setSelectedRepo}
          repositories={uniqueRepos}
        />
      </div>
      <div className="flex">
        <FilterPanel pullRequests={pullRequests} onFilterChange={setFilters} />
        <div className="ml-6 flex-grow">
          {error && (
            <div className="mb-4">
              <ErrorAlert message={error.message} />
            </div>
          )}
          {isLoading ? (
            <Spinner />
          ) : (
            <PullRequestList pullRequests={error ? [] : filteredPullRequests} />
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
