import React, { useEffect, useRef, useState } from 'react';
import { NavArrowDown, FilterList } from 'iconoir-react';

interface TimePeriodSelectorProps {
    selectedPeriod: string;
    onSelect: (period: string) => void;
    timePeriods: { value: string; label: string }[];
}

const TimePeriodSelector: React.FC<TimePeriodSelectorProps> = ({
    selectedPeriod,
    onSelect,
    timePeriods
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    // Format date range for display
    const getDateRange = (periodValue: string): string => {
        const today = new Date();
        const formatDate = (date: Date) =>
            date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

        let daysToSubtract;
        switch (periodValue) {
            case '7d':
                daysToSubtract = 6;
                break;
            case '4w':
                daysToSubtract = 27;
                break;
            case '12w':
                daysToSubtract = 83;
                break;
            case '24w':
                daysToSubtract = 167;
                break;
            default:
                daysToSubtract = 6;
        }

        const startDate = new Date(today);
        startDate.setDate(startDate.getDate() - daysToSubtract);
        return `${formatDate(startDate)} - ${formatDate(today)}`;
    };

    // Handle click outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSelect = (value: string) => {
        onSelect(value);
        setIsOpen(false);
    };

    const getCurrentLabel = () => {
        return timePeriods.find(period => period.value === selectedPeriod)?.label || 'Last 7 days';
    };

    return (
        <div className="relative inline-block text-left" ref={dropdownRef}>
            <div>
                <button
                    type="button"
                    className="inline-flex justify-between items-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <span className="flex items-center">
                        <FilterList className="mr-2 h-4 w-4" />
                        <span className="truncate">{getCurrentLabel()}</span>
                    </span>
                    <NavArrowDown
                        className={`ml-2 h-5 w-5 transition-transform duration-200 ${
                            isOpen ? 'transform rotate-180' : ''
                        }`}
                        aria-hidden="true"
                    />
                </button>
            </div>

            {isOpen && (
                <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {timePeriods.map((period) => (
                            <button
                                key={period.value}
                                className={`block w-full text-left px-4 py-2 text-sm ${
                                    selectedPeriod === period.value
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700 hover:bg-gray-50 hover:text-gray-900'
                                }`}
                                role="menuitem"
                                onClick={() => handleSelect(period.value)}
                            >
                                <div>{period.label}</div>
                                <div className="text-xs text-gray-500">
                                    {getDateRange(period.value)}
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TimePeriodSelector;
