import React, { createContext, useContext, useEffect, useState } from 'react';
import { PullRequest } from '../types/pullRequest';
import { useAuth } from './Auth';

interface InboxContextType {
  pullRequests: PullRequest[];
  isLoading: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
}

const InboxContext = createContext<InboxContextType | undefined>(undefined);

export const InboxProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { token, userId } = useAuth();
  const [pullRequests, setPullRequests] = useState<PullRequest[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchPullRequests = async () => {
    if (!token || !userId) {
        setError(new Error('Authentication required'));
        return;
    }

    setIsLoading(true);
    setError(null);
    try {
        const response = await fetch(`https://api.maxium.ai/v1/inbox/${userId}/pull-requests`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-Verification-Token': token
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch pull requests');
        }
        const data = await response.json();
        const transformedData: PullRequest[] = data.pull_requests.map((pr: any) => ({
          title: pr.title,
          author: pr.author.github_username,
          repo: pr.repo.github_repository,
          prNumber: `#${pr.number}`,
          additions: pr.n_additions,
          deletions: pr.n_deletions,
          updatedAgo: formatTimeAgo(new Date(pr.updated_at)),
          avatarUrl: pr.author.avatar_url || 'unknown',
          baseBranch: pr.base_branch,
          reviewedBy: pr.reviewers,
          taskLabels: pr.labels || [],
          ci: pr.ci.toLowerCase(),
          size: pr.size.charAt(0).toUpperCase() + pr.size.slice(1).toLowerCase(),
          status: pr.status.toLowerCase(),
        }));
        setPullRequests(transformedData);
    } catch (err) {
        setError(err instanceof Error ? err : new Error('Unknown error'));
        setPullRequests([]);
    } finally {
        setIsLoading(false);
    }
  };

  useEffect(() => {
    if (token && userId) {
      fetchPullRequests();
    }
  }, [token, userId]);

  return (
    <InboxContext.Provider value={{ pullRequests, isLoading, error, refetch: fetchPullRequests }}>
      {children}
    </InboxContext.Provider>
  );
};

export const useInbox = () => {
  const context = useContext(InboxContext);
  if (context === undefined) {
    throw new Error('useInbox must be used within an InboxProvider');
  }
  return context;
};

const formatTimeAgo = (date: Date): string => {
    const now = new Date();
    const utcNow = new Date(now.getTime() - (now.getTimezoneOffset() * 60000));
    const diffInMinutes = Math.floor((utcNow.getTime() - date.getTime()) / (1000 * 60));

    if (diffInMinutes < 60) {
      return `${diffInMinutes}min`;
    }
    if (diffInMinutes < 24 * 60) {
      return `${Math.floor(diffInMinutes / 60)}hr`;
    }
    return `${Math.floor(diffInMinutes / (60 * 24))}d`;
};
