import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { useAuth } from './Auth';
import { subDays, format } from 'date-fns';


export interface TimeSeriesDataPoint {
  date: string;
  value: number;
}

interface MetricItem {
    value: number;
    pop_change: number;
    negative_direction: 'up' | 'down';
}

interface MetricDistribution {
  w: number;
  w1: number;
  w2: number;
  w3: number;
  w4: number;
}

interface DirectionMetricItem {
    colour: string;
    distribution: MetricDistribution;
  }

type MetricData = MetricItem | MetricDistribution | DirectionMetricItem;

interface CategoryMetrics {
    [key: string]: MetricItem | MetricDistribution | DirectionMetricItem;
  }

export interface Metric {
  title: string;
  getValue: (repository: string) => number | TimeSeriesDataPoint[];
  getChangePercentage: (repository: string) => number;
  isPercentage: boolean;
  isTimeSeries: boolean;
  negative: "up" | "down";
  colour: string;
}

interface EngineeringMetricsResponse {
  user_id: string;
  last_updated_at: string;
  metrics: {
    [period: string]: {
      start_date: string;
      end_date: string;
      repos: string[];
      repo_level: Record<string, {
        velocity_metrics: Record<string, MetricItem | MetricDistribution>;
        direction_metrics: Record<string, DirectionMetricItem>;
        health_metrics: Record<string, MetricItem>;
      }>;
      overall: {
        velocity_metrics: Record<string, MetricItem | MetricDistribution>;
        direction_metrics: Record<string, DirectionMetricItem>;
        health_metrics: Record<string, MetricItem>;
      };
      time_period_description: string;
    }
  }
}

interface MetricsContextValue {
    metrics: {
      velocityMetrics: Metric[];
      healthMetrics: Metric[];
      directionMetrics: Metric[];
    } | null;
    loading: boolean;
    error: Error | null;
    selectedPeriod: string;
    selectedRepo: string;
    setSelectedPeriod: (period: string) => void;
    setSelectedRepo: (repo: string) => void;
    refreshMetrics: () => Promise<void>;
    availableRepos: string[];
    timePeriods: { value: string; label: string }[];
  }

  const MetricsContext = createContext<MetricsContextValue | undefined>(undefined);

  const timePeriods = [
    { value: '7d', label: 'Last 7 Days' },
    { value: '4w', label: 'Last 4 Weeks' },
    { value: '12w', label: 'Last 12 Weeks' },
    { value: '24w', label: 'Last 24 Weeks' },
  ];

    const convertToTimeSeriesData = (data: MetricDistribution | DirectionMetricItem): TimeSeriesDataPoint[] => {
    const today = new Date();
    const result: TimeSeriesDataPoint[] = [];

    type WeekKey = 'w' | 'w1' | 'w2' | 'w3' | 'w4';

    const weekData: Array<{ key: WeekKey, daysAgo: number }> = [
      { key: 'w', daysAgo: 0 },
      { key: 'w1', daysAgo: 7 },
      { key: 'w2', daysAgo: 14 },
      { key: 'w3', daysAgo: 21 },
      { key: 'w4', daysAgo: 28 },
    ];

    weekData.forEach(({ key, daysAgo }) => {
      const date = subDays(today, daysAgo);
      let value: number;

      if (isDirectionMetricValue(data)) {
        value = data.distribution[key];
      } else {
        value = data[key];
      }

      result.push({
        date: format(date, 'yyyy-MM-dd'),
        value
      });
    });

    return result;
  };

  const isMetricValue = (data: MetricData): data is MetricItem => {
    return 'value' in data && 'pop_change' in data;
  };

  const isTimeSeriesValue = (data: MetricData): data is MetricDistribution => {
    return 'w' in data && !('distribution' in data);
  };

  const isDirectionMetricValue = (data: MetricData): data is DirectionMetricItem => {
    return 'distribution' in data && 'colour' in data;
  };

  const createMetricAdapter = (
      periodData: any,
      metricPath: string,
      title: string,
      isPercentage = false,
      isTimeSeries = false,
      negative: "down" | "up" = "down",
      colour: string = ''
    ): Metric => {
      const getMetricData = (repo: string): MetricData => {
        const metrics = repo === 'All repositories'
          ? periodData.overall
          : periodData.repo_level[repo];

        const [category, metric] = metricPath.split('.');
        const categoryData = metrics[category] as CategoryMetrics;
        return categoryData[metric];
      };

    const getValue = (repo: string): number | TimeSeriesDataPoint[] => {
      const data = getMetricData(repo);

      if (isDirectionMetricValue(data)) {
        return isTimeSeries
          ? convertToTimeSeriesData(data)
          : data.distribution.w;
      }

      if (isTimeSeriesValue(data)) {
        return isTimeSeries
          ? convertToTimeSeriesData(data)
          : data.w;
      }

      if (isMetricValue(data)) {
        return data.value;
      }

      throw new Error(`Invalid metric data for ${metricPath}`);
    };

    const getChangePercentage = (repo: string): number => {
      const data = getMetricData(repo);

      if (isDirectionMetricValue(data)) {
        const { distribution } = data;
        return ((distribution.w - distribution.w1) / distribution.w1) * 100;
      }

      if (isTimeSeriesValue(data)) {
        return ((data.w - data.w1) / data.w1) * 100;
      }

      if (isMetricValue(data)) {
        return data.pop_change * 100;
      }

      throw new Error(`Invalid metric data for ${metricPath}`);
    };

    const getMetricColour = (repo: string): string => {
      const data = getMetricData(repo);
      if (isDirectionMetricValue(data)) {
        return `#${data.colour}`;
      }
      return colour || '#666666';
    };

    return {
      title,
      getValue,
      getChangePercentage,
      isPercentage,
      isTimeSeries,
      negative,
      colour: getMetricColour('All repositories')
    };
  };

export function MetricsProvider({ children }: { children: React.ReactNode }) {
    const [rawData, setRawData] = useState<EngineeringMetricsResponse | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [selectedPeriod, setSelectedPeriod] = useState('7d');
    const [selectedRepo, setSelectedRepo] = useState('All repositories');
    const { token, userId } = useAuth();

    const metrics = useMemo(() => {
      if (!rawData) return null;

    // Get the metrics for the selected time period
    const periodData = rawData.metrics[selectedPeriod];
    if (!periodData) return null;

    const velocityMetrics: Metric[] = [
      createMetricAdapter(
        periodData,
        'velocity_metrics.prs_merged',
        'PRs Merged per Engineer (median)',
      ),
      createMetricAdapter(
        periodData,
        'velocity_metrics.lines_modified',
        'Lines Modified per Engineer (median)'
      ),
      createMetricAdapter(
        periodData,
        'velocity_metrics.total_output',
        'Total Output per Engineer (median)'
      ),
      createMetricAdapter(
        periodData,
        'velocity_metrics.review_time',
        'Hours to First Review (median)',
        false,
        false,
        "up"
      ),
      createMetricAdapter(
        periodData,
        'velocity_metrics.time_to_merge',
        'Hours from First Commit to Merge (median)',
        false,
        false,
        "up"
      ),
      createMetricAdapter(
        periodData,
        'velocity_metrics.output_trend',
        'Total Output per Engineer (trend)',
        false,
        true
      ),
    ];

    const healthMetrics: Metric[] = [
      createMetricAdapter(
        periodData,
        'health_metrics.churn_rate',
        'Engineers Churn Rate (last 12 months)',
        true,
        false,
        "up"
      ),
      createMetricAdapter(
        periodData,
        'health_metrics.prop_excessive_load',
        'Proportion of Engineers under Excessive Load',
        true,
        false,
        "up"
      ),
      createMetricAdapter(
        periodData,
        'health_metrics.prop_context_switch',
        'Unsustainable Activity Rate per Engineer (median)',
        true,
        false,
        "up"
      ),
    ];

    const directionMetrics: Metric[] = [
        createMetricAdapter(
            periodData,
          'direction_metrics.mx/bugs',
          'Bugs',
          true,
          true,
          "down",
          periodData.overall.direction_metrics['mx/bugs'].colour
        ),
        createMetricAdapter(
            periodData,
          'direction_metrics.mx/tests',
          'Tests',
          true,
          true,
          "down",
          periodData.overall.direction_metrics['mx/tests'].colour
        ),
        createMetricAdapter(
            periodData,
          'direction_metrics.mx/refactors',
          'Refactors',
          true,
          true,
          "down",
          periodData.overall.direction_metrics['mx/refactors'].colour
        ),
        createMetricAdapter(
            periodData,
            'direction_metrics.mx/new-features',
            'New Features',
            true,
            true,
            "down",
            periodData.overall.direction_metrics['mx/new-features'].colour
        ),
        createMetricAdapter(
            periodData,
            'direction_metrics.mx/releases',
            'Releases',
            true,
            true,
            "down",
            periodData.overall.direction_metrics['mx/releases'].colour
          ),
        createMetricAdapter(
            periodData,
            'direction_metrics.mx/docs',
            'Documentation',
            true,
            true,
            "down",
            periodData.overall.direction_metrics['mx/docs'].colour
        ),
        createMetricAdapter(
            periodData,
            'direction_metrics.mx/infra',
            'Infrastructure',
            true,
            true,
            "down",
            periodData.overall.direction_metrics['mx/infra'].colour
        ),
        createMetricAdapter(
            periodData,
            'direction_metrics.mx/enhance',
            'Enhancements',
            true,
            true,
            "down",
            periodData.overall.direction_metrics['mx/enhance'].colour
        ),
        createMetricAdapter(
            periodData,
            'direction_metrics.mx/security',
            'Security',
            true,
            true,
            "down",
            periodData.overall.direction_metrics['mx/security'].colour
        ),
      ];

    return { velocityMetrics, healthMetrics, directionMetrics };
  }, [rawData, selectedPeriod]);

  // Available repos derived from raw data
  const availableRepos = useMemo(() => {
    if (!rawData?.metrics[selectedPeriod]) return [];
    return ['All repositories', ...rawData.metrics[selectedPeriod].repos];
  }, [rawData, selectedPeriod]);

  const fetchMetrics = useCallback(async () => {
    if (!token || !userId) {
        setError(new Error('Authentication required'));
        setLoading(false);
        return;
      }
    try {
      setLoading(true);

      const response = await fetch(`https://api.maxium.ai/v1/metrics/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Verification-Token': token
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch metrics');
      }

      const data: EngineeringMetricsResponse = await response.json() as EngineeringMetricsResponse;
      setRawData(data);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Unknown error occurred'));
    } finally {
      setLoading(false);
    }
  }, [token, userId]);

  const refreshMetrics = useCallback(() => {
    return fetchMetrics();
  }, [fetchMetrics]);

  React.useEffect(() => {
    if (token && userId) {
      fetchMetrics();
    } else {
      setError(new Error('Authentication required'));
      setLoading(false);
    }
  }, [token, userId, fetchMetrics]);

  const contextValue: MetricsContextValue = {
    metrics,
    loading,
    error,
    selectedPeriod,
    selectedRepo,
    setSelectedPeriod,
    setSelectedRepo,
    refreshMetrics,
    availableRepos,
    timePeriods,
  };

  return (
    <MetricsContext.Provider value={contextValue}>
      {children}
    </MetricsContext.Provider>
  );
}

export function useMetrics() {
  const context = useContext(MetricsContext);
  if (context === undefined) {
    throw new Error('useMetrics must be used within a MetricsProvider');
  }
  return context;
}

export function useVelocityMetrics() {
  const { metrics, loading, error, selectedPeriod, selectedRepo } = useMetrics();
  return {
    metrics: metrics?.velocityMetrics ?? [],
    loading,
    error,
    selectedPeriod,
    selectedRepo,
  };
}

export function useHealthMetrics() {
  const { metrics, loading, error, selectedPeriod, selectedRepo } = useMetrics();
  return {
    metrics: metrics?.healthMetrics ?? [],
    loading,
    error,
    selectedPeriod,
    selectedRepo,
  };
}

export function useDirectionMetrics() {
    const { metrics, loading, error, selectedPeriod, selectedRepo } = useMetrics();
    return {
      metrics: metrics?.directionMetrics ?? [],
      loading,
      error,
      selectedPeriod,
      selectedRepo,
    };
  }
