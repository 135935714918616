export interface Automation {
    title: string;
    description: string;
    icon: string;
    comingSoon: boolean;
  }

  export const automations: Automation[] = [
    {
      title: "Maxium Auto-stack",
      description: 'Use this automation to auto-stack pull requests above a certain size across your organisation. Stacked pull requests are easier to review, faster to merge, and help structure your code.',
      icon: 'auto-stack',
      comingSoon: true,
    },
    {
      title: "Documentation Sync",
      description: 'Keeping your documentation up-to-date is needed to ensure alignment as your product grows. Worry less about it and let us create and maintain your single source of truth for code documentation.',
      icon: 'documentation',
      comingSoon: true,
    },
    {
      title: "CI Optimisations",
      description: 'Improve the speed at which your engineers can ship by skipping redundant tests and caching results from tests with robust CI optimisations',
      icon: '10x-engineer',
      comingSoon: true,
    },
  ];
