import React, { useState } from 'react';
import { NavArrowUp, NavArrowDown, InfoCircle } from 'iconoir-react';
import { Metric, TimeSeriesDataPoint } from '../context/Metrics';

interface TaskDistributionTableProps {
  metrics: Metric[];
  repository?: string;
}

interface TaskRowData {
  label: string;
  colour: string;
  timeSeriesData: TimeSeriesDataPoint[];
}

const hexToRgb = (hex: string) => {
  const cleanHex = hex.replace('#', '');
  const r = parseInt(cleanHex.substring(0, 2), 16);
  const g = parseInt(cleanHex.substring(2, 4), 16);
  const b = parseInt(cleanHex.substring(4, 6), 16);
  return { r, g, b };
};

const calculateChange = (current: number, previous: number): number | null => {
  if (previous === 0) return null;
  return ((current - previous) / previous) * 100;
};

const TaskRow: React.FC<TaskRowData> = ({
  label,
  colour,
  timeSeriesData
}) => {
  const rgbColor = hexToRgb(colour);
  const backgroundColor = `rgb(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b})`;

  // Take only the last 4 weeks of data
  const lastFourWeeks = timeSeriesData.slice(-4);

  // Calculate week-over-week changes
  const changes = lastFourWeeks.map((point, index) => {
    if (index === 0) return null;
    const currentValue = point.value * 100;
    const previousValue = lastFourWeeks[index - 1].value * 100;
    return calculateChange(currentValue, previousValue);
  });

  return (
    <div className="flex items-center border-b border-gray-200 py-4">
      <div className="w-1/5 flex items-center">
        <div
          className="w-3 h-3 rounded-full mr-3"
          style={{ backgroundColor }}
        ></div>
        <span className="text-sm font-medium">{label}</span>
      </div>
      {lastFourWeeks.map((point, index) => (
        <div key={index} className="w-1/5 px-2">
          <div className="flex items-center justify-between mb-1">
            <span className="text-2xl font-bold">{(point.value * 100).toFixed(1)}%</span>
          </div>
          <div className="flex items-center">
            {/* Bar container with fixed width */}
            <div className="flex-1">
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div
                  className="h-2 rounded-full"
                  style={{
                    backgroundColor,
                    width: `${Math.min(100, point.value * 100)}%`
                  }}
                ></div>
              </div>
            </div>
            {/* Change indicator with fixed width */}
            {changes[index] !== null && (
              <div className="ml-2 w-12 flex-shrink-0">
                <span className={`${changes[index]! > 0 ? 'text-green-500' : 'text-red-500'} flex items-center text-xs`}>
                  {changes[index]! > 0 ? <NavArrowUp className="w-3 h-3" /> : <NavArrowDown className="w-3 h-3" />}
                  <span className="ml-0.5">{Math.abs(changes[index]!).toPrecision(2)}%</span>
                </span>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const TaskDistributionTable = ({ metrics, repository = 'All repositories' }: TaskDistributionTableProps) => {
  const [expanded, setExpanded] = useState(false);

  const taskData: TaskRowData[] = metrics.map(metric => ({
    label: metric.title,
    colour: metric.colour,
    timeSeriesData: metric.getValue(repository) as TimeSeriesDataPoint[]
  }));

  const visibleTasks = expanded ? taskData : taskData.slice(0, 3);

  return (
    <div className="bg-white p-4 rounded-lg border border-gray-200">
      <div className="flex mb-4 font-medium text-gray-700 border-b pb-2">
        <div className="w-1/5">Task Type</div>
        <div className="w-1/5 px-2">W-3</div>
        <div className="w-1/5 px-2">W-2</div>
        <div className="w-1/5 px-2">W-1</div>
        <div className="w-1/5 px-2">Current Week</div>
      </div>
      {visibleTasks.map((task, index) => (
        <TaskRow key={index} {...task} />
      ))}
      {taskData.length > 3 && (
        <button
          className="mt-4 flex items-center text-gray-500 hover:text-gray-700"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? (
            <>
              <NavArrowUp className="mr-1" />
              <span className="text-sm">Show less</span>
            </>
          ) : (
            <>
              <NavArrowDown className="mr-1" />
              <span className="text-sm">Show all {taskData.length} task types</span>
            </>
          )}
        </button>
      )}
      <div className="mt-4 flex items-center text-sm text-gray-500 italic">
        <InfoCircle className="w-4 h-4 text-gray-400 mr-1" />
        <p>Please note filters don't apply to the task distribution table above yet.</p>
      </div>
    </div>
  );
};

export default TaskDistributionTable;
