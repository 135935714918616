import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import HomePage from './pages/Home';
import InsightsPage from './pages/Insights';
import AutomationsPage from './pages/Automations';
import SignalsPage from './pages/Signals';
import SettingsPage from './pages/Settings';
import LoginPage from './pages/Login';
import SetupPage from './pages/Setup';
import Sidebar from './components/Sidebar';
import { AuthProvider, useAuth } from './context/Auth';
import { InboxProvider } from './context/Inbox';
import { MetricsProvider } from './context/Metrics';

const ProtectedRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
  const { isAuthenticated, avatarUrl } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar avatarUrl={avatarUrl} />
      <div className="flex-1 pt-6 pb-6 pr-6 overflow-auto">
        <div className="bg-white rounded-lg shadow-lg p-6 h-full overflow-y-auto max-h-screen">
          <InboxProvider>
            <MetricsProvider>{element}</MetricsProvider>
          </InboxProvider>
        </div>
      </div>
    </div>
  );
};

const PublicRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
  return (
    <div className="min-h-screen">
      {element}
    </div>
  );
};

const SettingsRouteWrapper: React.FC = () => {
  const { organisations, username, email } = useAuth();

  return (
    <SettingsPage
      organisations={organisations}
      username={username || ''}
      email={email || ''}
      onOrganisationSelect={(orgName) => {
        console.log(`Selected organisation: ${orgName}`);
      }}
    />
  );
};

const AppContent: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (isAuthenticated && location.pathname === '/login') {
    return <Navigate to="/" replace />;
  }

  return (
    <Routes>
      <Route path="/login" element={<PublicRoute element={<LoginPage />} />} />
      <Route path="/setup" element={<PublicRoute element={<SetupPage />} />} />
      {/* Protected Routes */}
      <Route path="/" element={<ProtectedRoute element={<HomePage />} />} />
      <Route path="/insights" element={<ProtectedRoute element={<InsightsPage />} />} />
      <Route path="/automations" element={<ProtectedRoute element={<AutomationsPage />} />} />
      <Route path="/signals" element={<ProtectedRoute element={<SignalsPage />} />} />
      <Route
        path="/settings"
        element={<ProtectedRoute element={<SettingsRouteWrapper />} />}
      />
    </Routes>
  );
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

export default App;
