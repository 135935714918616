export interface TimeSeriesDataPoint {
  date: string;
  value: number;
}

export interface SignalsMetric {
  title: string;
  value: number | TimeSeriesDataPoint[];
  changePercentage: number;
  isPercentage: boolean;
  isTimeSeries: boolean;
  negative: "up" | "down";
}

export const signalsMetrics: SignalsMetric[] = [
  {
    title: "Active Risks",
    value: 0,
    changePercentage: 0,
    isPercentage: false,
    isTimeSeries: false,
    negative: "down"
  },
  {
    title: "Active Opportunities",
    value: 0,
    changePercentage: 0,
    isPercentage: false,
    isTimeSeries: false,
    negative: "up"
  },
  {
    title: "Active Automations",
    value: 0,
    changePercentage: 0,
    isPercentage: false,
    isTimeSeries: false,
    negative: "up"
  },
];
