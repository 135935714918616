import React from 'react';
import { MetricCard } from '../components/MetricCard';
import { signalsMetrics } from '../sample_data/metrics';
import SignalsTable from '../components/SignalsTable';

interface TableRow {
  issueType: string;
  category: 'Health' | 'Speed' | 'Direction';
  description: string;
  identifiedOn: string;
  resolutionDate: string;
  status: 'Automation Available' | 'Needs Attention' | 'Resolved';
}

const tableData: TableRow[] = [
  // {
  //   issueType: 'Risk',
  //   category: 'Health',
  //   description: 'The infrastructure team has been under an excessive work load over the last 7 days',
  //   identifiedOn: '25/10/2024',
  //   resolutionDate: 'N/A',
  //   status: 'Automation Available',
  // },
  // {
  //   issueType: 'Risk',
  //   category: 'Speed',
  //   description: 'The CI step (unit tests) in backend-api repo is considerably slower this week in comparison to the previous period',
  //   identifiedOn: '25/10/2024',
  //   resolutionDate: 'N/A',
  //   status: 'Needs Attention',
  // },
  // {
  //   issueType: 'Opportunity',
  //   category: 'Direction',
  //   description: 'The team can potentially avoid multiple production bugs by improving the test coverage',
  //   identifiedOn: '25/10/2024',
  //   resolutionDate: '25/10/2024',
  //   status: 'Resolved',
  // }
];

const SignalsPage: React.FC = () => {
  return (
    <div className="p-6 space-y-8">
      <h1 className="text-2xl font-semibold">Signals</h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {signalsMetrics.map((metric, index) => (
          <MetricCard
            key={index}
            title={metric.title}
            value={metric.value}
            changePercentage={metric.changePercentage}
            isPercentage={metric.isPercentage}
            isTimeSeries={metric.isTimeSeries}
            negative={metric.negative}
            timePeriod="7d"
            repository="All repositories"
          />
        ))}
      </div>

      <SignalsTable
        title="History"
        description="List of active and previously resolved signals highlighted by Maxium"
        data={tableData}
      />
    </div>
  );
};

export default SignalsPage;
