import React, { useEffect, useMemo, useState } from 'react';
import TimePeriodSelector from './TimePeriodSelector';
import CategoryFilter from './CategoryFilter';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Compass, DashboardSpeed, Activity } from 'iconoir-react';
import FloatingActionMenu from './FloatingActionMenu';

interface TableRow {
  issueType: string;
  category: 'Health' | 'Speed' | 'Direction';
  description: string;
  identifiedOn: string;
  resolutionDate: string;
  status: 'Automation Available' | 'Needs Attention' | 'Resolved';
}

interface SignalsTableProps {
  title: string;
  description: string;
  data: TableRow[];
}

const CategoryIcon = ({ category }: { category: TableRow['category'] }) => {
  const iconProps = {
    className: 'h-5 w-5',
    'aria-hidden': true
  };

  switch (category) {
    case 'Health':
      return <Activity {...iconProps} className="text-indigo-400" />;
    case 'Speed':
      return <DashboardSpeed {...iconProps} className="text-indigo-400" />;
    case 'Direction':
      return <Compass {...iconProps} className="text-indigo-400" />;
    default:
      return null;
  }
};

const SignalsTable: React.FC<SignalsTableProps> = ({ title, description, data }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState<string | null>(null);

  const tableCategories = [
    { label: 'Health', value: 'Health' },
    { label: 'Speed', value: 'Speed' },
    { label: 'Direction', value: 'Direction' }
  ];

  useEffect(() => {
    const categoryParam = searchParams.get('category');
    if (categoryParam && tableCategories.some(cat => cat.value === categoryParam)) {
      setSelectedCategory(categoryParam);
    }

    if (categoryParam) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('category');
      const queryString = newSearchParams.toString();
      const newPath = queryString ? `?${queryString}` : '';
      navigate(`${window.location.pathname}${newPath}`, { replace: true });
    }
  }, []);

  const handleCategoryChange = (category: string | null) => {
    setSelectedCategory(category);
  };

  const handleRowSelect = (index: number) => {
    const newSelected = new Set(selectedRows);
    if (newSelected.has(index)) {
      newSelected.delete(index);
    } else {
      newSelected.add(index);
    }
    setSelectedRows(newSelected);
    setSelectAll(newSelected.size === filteredData.length);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows(new Set());
    } else {
      setSelectedRows(new Set(filteredData.map((_, index) => index)));
    }
    setSelectAll(!selectAll);
  };

  const handleAcknowledge = () => {
    console.log('Acknowledging rows:', selectedRows);
  };

  const handleMonitor = () => {
    console.log('Monitoring rows:', selectedRows);
  };

  const handleAutoResolve = () => {
    console.log('Auto-resolving rows:', selectedRows);
  };

  const filteredData = useMemo(() => {
    let filtered = [...data];

    if (selectedCategory && tableCategories.some(cat => cat.value === selectedCategory)) {
      filtered = filtered.filter(row => row.category === selectedCategory);
    }

    if (selectedPeriod) {
      const currentDate = new Date();
      const getDateBefore = (days: number) => {
        const date = new Date();
        date.setDate(date.getDate() - days);
        return date;
      };

      let daysToSubtract: number;
      switch (selectedPeriod) {
        case '7d':
          daysToSubtract = 7;
          break;
        case '4w':
          daysToSubtract = 28;
          break;
        case '12w':
          daysToSubtract = 84;
          break;
        case '24w':
          daysToSubtract = 168;
          break;
        default:
          daysToSubtract = 7;
      }

      const filterDate = getDateBefore(daysToSubtract);

      filtered = filtered.filter(row => {
        const rowDate = new Date(row.identifiedOn.split('/').reverse().join('-'));
        return rowDate >= filterDate && rowDate <= currentDate;
      });
    }

    return filtered;
  }, [data, selectedCategory, selectedPeriod]);

  const handleTimePeriodChange = (period: string) => {
    setSelectedPeriod(period);
  };

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="p-6">
        <div className="flex justify-between items-start mb-2">
          <div>
            <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
            <p className="text-gray-500 text-sm">{description}</p>
          </div>
          <div className="flex gap-4">
            <CategoryFilter
              categories={tableCategories}
              selectedCategory={selectedCategory}
              onCategoryChange={handleCategoryChange}
              placeholder="All Categories"
            />
            <TimePeriodSelector
              selectedPeriod={selectedPeriod || '7d'}
              onSelect={handleTimePeriodChange}
              timePeriods={[
                { value: '7d', label: 'Last 7 days' },
                { value: '4w', label: 'Last 4 weeks' },
                { value: '12w', label: 'Last 12 weeks' },
                { value: '24w', label: 'Last 24 weeks' }
              ]}
            />
          </div>
        </div>

        <div className="mt-4 overflow-x-auto">
          <table className="min-w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="w-12 px-6 py-3">
                  <input
                    type="checkbox"
                    className="rounded border-gray-300"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Issue type
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Category
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Description
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Identified on
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Resolution date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredData.length === 0 ? (
                <tr>
                  <td colSpan={7} className="px-6 py-4 text-center text-gray-500">
                    No signals available yet
                  </td>
                </tr>
              ) : (
                filteredData.map((row, index) => (
                  <tr
                    key={index}
                    className={selectedRows.has(index) ? 'bg-indigo-50' : ''}
                  >
                    <td className="px-6 py-4">
                      <input
                        type="checkbox"
                        className="rounded border-gray-300"
                        checked={selectedRows.has(index)}
                        onChange={() => handleRowSelect(index)}
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {row.issueType}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      <div className="flex items-center space-x-2">
                        <CategoryIcon category={row.category} />
                        <span>{row.category}</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-900">
                      {row.description}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {row.identifiedOn}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {row.resolutionDate}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      <div className="flex items-center space-x-4">
                        <div className={`text-xs font-semibold px-2 py-1 rounded-full ${
                          row.status === 'Automation Available'
                            ? 'bg-indigo-100 text-indigo-600'
                            : 'bg-gray-100 text-gray-600'
                        }`}>
                          {row.status}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      <FloatingActionMenu
        selectedRows={selectedRows}
        totalRows={filteredData.length}
        data={filteredData}
        onAcknowledge={handleAcknowledge}
        onMonitor={handleMonitor}
        onAutoResolve={handleAutoResolve}
      />
    </div>
  );
};

export default SignalsTable;
