import { PlusCircle, CheckCircleSolid } from 'iconoir-react';
import React, { useState } from 'react';
import { Organisation } from '../types/organisation';

interface SettingsPageProps {
  organisations: Organisation[];
  username: string;
  email: string;
  onOrganisationSelect: (orgName: string) => void;
}

const SettingsPage: React.FC<SettingsPageProps> = ({ organisations: initialOrganisations, username, email, onOrganisationSelect }) => {
  const [organisations, setOrganisations] = useState(initialOrganisations);

  const handleOrganisationSelect = (orgName: string) => {
    const updatedOrganisations = organisations.map(org => ({
      ...org,
      isSelected: org.name === orgName
    }));
    setOrganisations(updatedOrganisations);
    onOrganisationSelect(orgName);
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold mb-6">Settings</h1>
      <div className="border-t border-gray-300 w-full"></div>
      <div className="max-w-2xl mx-auto mt-6">
        <h2 className="text-xl font-bold mb-4">User</h2>
        <div className="mb-6 flex space-x-4">
          <div className="flex-1">
            <p className="text-sm font-medium text-gray-700 mb-1">Username</p>
            <div className="bg-gray-100 rounded-md p-2 text-gray-800">{username}</div>
          </div>
          <div className="flex-1">
            <p className="text-sm font-medium text-gray-700 mb-1">Email</p>
            <div className="bg-gray-100 rounded-md p-2 text-gray-800">{email}</div>
          </div>
        </div>

        <h2 className="text-xl font-bold mb-4">Authenticated Organisations</h2>
        <p className="text-gray-600 mb-4">
          If your organisation uses SAML SSO please ensure that you have an active SAML session on GitHub.
          Having trouble with GitHub authentication?{' '}
          <a href="mailto:founders@maxium.ai" className="text-blue-500 hover:underline">
            Contact support
          </a>
        </p>
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 mb-4">
          <div className="p-4 flex items-center justify-between">
            <span className="font-medium">Github App</span>
          </div>
          {organisations.map((org, index) => (
            <div
              key={index}
              className="border-t border-gray-200 p-4 flex items-center justify-between cursor-pointer hover:bg-gray-50"
              onClick={() => handleOrganisationSelect(org.name)}
            >
              <div className="flex items-center">
                <svg className="w-6 h-6 mr-2 text-gray-500" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 0C5.374 0 0 5.373 0 12c0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23A11.509 11.509 0 0112 5.803c1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576C20.566 21.797 24 17.3 24 12c0-6.627-5.373-12-12-12z" />
                </svg>
                <span>{org.name}</span>
                {org.isSelected && (
                  <CheckCircleSolid className="w-4 h-4 ml-2 text-green-600" />
                )}
              </div>
              <div className="flex items-center text-sm text-gray-500">
                <span className="mr-2">Used on {org.repoCount} repo{org.repoCount !== 1 ? 's' : ''}</span>
                <a
                  href={`https://github.com/settings/installations/${org.installationId}`}
                  className="text-gray-400 hover:text-gray-600"
                  onClick={(e) => e.stopPropagation()}
                >
                  <PlusCircle />
                </a>
              </div>
            </div>
          ))}
        </div>
        <a
          href="https://github.com/apps/maxium-bot/installations/select_target"
          className="block w-full py-2 px-4 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-center"
        >
          Add organisation
        </a>
      </div>
    </div>
  );
};

export default SettingsPage;
